import React from "react"
import * as S from "./styled"
import Date from "../date/Date"

const Visual = ({ year, days }: Props) => {
    return (
        <S.Container>
            <S.Background />
            <Date year={year} days={days} />
        </S.Container>
    )
}

interface Props {
    year: string
    days: string
}

export default Visual
