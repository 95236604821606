import React from "react"
import { H3 } from "@headers"
import * as S from "./styled"

import { GatsbyImage } from "gatsby-plugin-image"
import { PeopleProps } from "../types"

const People = ({ people }: Props) => {
    const generateGridClasses = () => {
        switch (people.length) {
            case 2:
                return `lg:col-span-6`
            case 3:
                return "lg:col-span-4"
            case 4:
                return "lg:col-span-3"
            default:
                return ""
        }
    }
    return (
        <S.People className="grid sm:grid-cols-12 gap-6">
            {people.map(
                ({ title, id, acf: { position, image, description } }) => (
                    <S.Person
                        className={`sm:col-span-6 ${generateGridClasses()}`}
                        key={id}
                    >
                        <GatsbyImage
                            image={
                                image.localFile.childImageSharp.gatsbyImageData
                            }
                            alt={title}
                        />
                        <H3 line={100} content={title} />
                        <S.Position
                            dangerouslySetInnerHTML={{ __html: position }}
                        />
                        <div
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </S.Person>
                )
            )}
        </S.People>
    )
}

interface Props {
    people: PeopleProps
}

export default People
