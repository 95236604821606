import React, { useState } from "react"
import { Heading } from "@common"
import { GridContainer } from "@styled"
import { gatsbyImage } from "@types"
import Project from "./project/Project"
import Navigation from "./navigation/Navigation"

const Projects = ({
    props: {
        header,
        subheader,
        backgroundText,
        projects,
        sectionId,
        navigationText,
    },
}: Props) => {
    const [active, setActive] = useState(1)
    return (
        <GridContainer id={sectionId}>
            <Heading
                position="left"
                header={header}
                subheader={subheader}
                backgroundText={backgroundText}
            />
            {projects.map(
                (
                    {
                        header,
                        content,
                        button,
                        images,
                        logo,
                        projectDate: { year, days },
                    },
                    index
                ) => (
                    <Project
                        key={index}
                        active={active === index + 1}
                        header={header}
                        content={content}
                        logo={logo}
                        button={button}
                        images={images}
                        year={year}
                        days={days}
                    />
                )
            )}
            <Navigation
                text={navigationText}
                active={active}
                setActive={setActive}
                projectsNumber={projects.length}
            />
        </GridContainer>
    )
}

interface Props {
    props: {
        backgroundText: string
        header: string
        subheader: string
        navigationText: string
        projects: Array<{
            header: string
            button: {
                link: string
                text: string
            }
            projectDate: {
                year: string
                days: string
            }
            content: string
            images: Array<{
                image: gatsbyImage
            }>
            logo: gatsbyImage
        }>
        sectionId: string
    }
}

export default Projects
