import React from "react"
import { graphql } from "gatsby"
import Template from "../Template"
import Seo from "../../common/Seo"
import SectionParser from "./section-parser/SectionParser"

const Page = ({ data }: Props) => {
    const {
        wpChildren: { nodes: sections },
        acfMainPage: { title, description },
    } = data.wpPage

    return (
        <Template>
            <Seo title={title} description={description} />
            {data && <SectionParser sections={sections} />}
        </Template>
    )
}

export const query = graphql`
    query ($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            acfMainPage {
                title
                description
            }
            wpChildren {
                nodes {
                    ... on WpPage {
                        ...ChildPage
                        ...Documents
                        ...Logos
                        ...MainBanner
                        ...Projects
                        ...Support
                        ...Team
                        ...WhoAreWe
                        menuOrder
                        id
                    }
                }
            }
        }
    }
`

interface Props {
    data: any
}

export default Page
