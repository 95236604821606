import React from "react"
import * as S from "./styled"
import { GridContainer } from "@styled"
import { gatsbyImage } from "@types"
import { GatsbyImage } from "gatsby-plugin-image"

const Logos = ({ props: { logos } }: Props) => {
    return (
        <GridContainer className="grid grid-cols-12 gap-2 md:gap-5">
            {logos.map(({ logo, link }) => (
                <S.Link
                    role="link"
                    key={link}
                    className="col-span-6 md:col-span-3"
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <GatsbyImage
                        image={logo.localFile.childImageSharp.gatsbyImageData}
                        alt=""
                    />
                </S.Link>
            ))}
        </GridContainer>
    )
}

interface Props {
    props: {
        logos: Array<{
            logo: gatsbyImage
            link: string
        }>
    }
}

export default Logos
