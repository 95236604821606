import React from "react"
import * as S from "./styled"
import { H3 } from "@headers"
import { GridContainer } from "@styled"
import Header from "./Header"

const Documents = ({
    props: { backgroundText, downloadText, header, subheader, documents },
}: Props) => {
    return (
        <S.Documents>
            <GridContainer>
                <S.Wrapper>
                    <Header
                        backgroundText={backgroundText}
                        subheader={subheader}
                        header={header}
                    />
                    <S.Files>
                        {documents.map(
                            ({ header, document: { mediaItemUrl } }, index) => (
                                <S.File
                                    key={header}
                                    last={index + 1 === documents.length}
                                >
                                    <H3 color="#fff" content={header} />
                                    <S.Download>
                                        <S.Icon />

                                        <a
                                            role="link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={mediaItemUrl}
                                            dangerouslySetInnerHTML={{
                                                __html: downloadText,
                                            }}
                                        />
                                    </S.Download>
                                </S.File>
                            )
                        )}
                    </S.Files>
                </S.Wrapper>
            </GridContainer>
        </S.Documents>
    )
}

interface Props {
    props: {
        backgroundText: string
        downloadText: string
        header: string
        subheader: string
        documents: Array<{
            header: string
            document: {
                mediaItemUrl: string
            }
        }>
    }
}

export default Documents
