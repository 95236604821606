import styled, { css, keyframes } from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const style = css`
    background: ${cssVariables.colors.primary.main};
    background-image: ${cssVariables.gradient.horizontal};
    background-position: 0 0;
    cursor: pointer;

    &:hover {
        background-position: 50% 0;
    }

    &:focus {
        outline: none;
    }
`

const scroll = keyframes`
  0% {
    top: 12px;
  }

  50% {
    top: 24px;
  }

  100% {
    top: 12px;
  }
`

const ScrollButton = styled.a`
    ${style}
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: -40px;
    left: calc(50% - 55px);
    border-radius: 40px;

    ${breakpoints.md} {
        bottom: 73px;
        left: calc(50% - 115px);
    }

    svg {
        width: 18px;
        min-width: 17px;
        position: relative;
        right: -30px;
        top: 10px;
        animation: ${scroll} 2s linear infinite;
    }
`

export { ScrollButton }
