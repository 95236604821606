import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"
import { GatsbyImage } from "gatsby-plugin-image"

const Image = styled.div``

const StyledImg = styled(GatsbyImage)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100%;

    & > img {
        object-fit: cover !important;
        object-position: 0% 0% !important;
    }
`

const Gallery = styled.div`
    display: grid;
    grid-template-columns: auto;
    column-gap: 10px;
    row-gap: 10px;
    padding-top: 20px;

    ${breakpoints.sm} {
        grid-template-columns: auto auto;
    }

    ${breakpoints.md} {
        grid-template-columns: auto auto auto;
    }
`

const GalleryItem = styled.div`
    position: relative;
`

const Button = styled.button<{ prev?: true; active: boolean }>`
    background: ${({ active }) =>
        active
            ? cssVariables.gradient.vertical
            : cssVariables.colors.gray.medium};

    cursor: ${({ active }) => (active ? "pointer" : "not-allowed")};

    transform: ${({ prev }) => (prev ? "rotate(180deg)" : "none")};
    width: 40px;
    height: 40px;
    border-radius: 50%;

    ${breakpoints.md} {
        width: 60px;
        height: 60px;
    }

    svg {
        width: 30px;
        height: 30px;
        margin-left: 0;

        ${breakpoints.md} {
            margin-left: 10px;
        }
    }
`

const LightboxModal = styled.div<{ visible: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 300;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${props => (props.visible ? "1" : "0")};
    visibility: ${props => (props.visible ? "visible" : "hidden")};

    ${breakpoints.md} {
        padding: 0;
    }
`
const LightboxContent = styled.div`
    position: relative;
    width: 360px;

    ${breakpoints.sm} {
        width: 480px;
    }

    ${breakpoints.md} {
        margin: 15px;
        width: 100%;
        max-width: 700px;
    }
`

const Controls = styled.div`
    position: absolute;
    bottom: -55px;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    z-index: 5;
    z-index: 5;

    ${breakpoints.md} {
        width: calc(100% + 140px);
        left: -70px;
        right: unset;
        bottom: 50%;
    }
`

const LeftRight = styled.div`
    button:first-child {
        margin-right: 10px;
    }
`

const Backdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
`

export {
    StyledImg,
    Image,
    Gallery,
    GalleryItem,
    Button,
    LightboxModal,
    LightboxContent,
    Controls,
    LeftRight,
    Backdrop,
}
