import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const Box = styled.div`
    background: ${cssVariables.gradient.horizontal};
    padding: 30px;
    color: #fff;
    border-radius: ${cssVariables.borderRadius};
    font-size: ${cssVariables.fonts.base};
    box-shadow: ${cssVariables.boxShadow};

    ${breakpoints.md} {
        margin-top: -100px;
        margin-left: 25%;
        width: 500px;
    }
`

const Header = styled.span`
    display: inline-block;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    font-family: ${cssVariables.fontFamily.secondary};
`

export { Box, Header }
