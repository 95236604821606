import React from "react"
import * as S from "./styled"
import { Arrow } from "@styled"

const Navigation = ({ active, setActive, projectsNumber, text }: Props) => {
    if (projectsNumber === 1) return null

    const setPrev = () => {
        setActive(prevState => {
            if (prevState === 1) return prevState
            return prevState - 1
        })
    }
    const setNext = () => {
        setActive(prevState => {
            if (prevState === projectsNumber) return prevState
            return prevState + 1
        })
    }
    return (
        <S.Navigation>
            <S.Text>
                <S.Numbers>
                    {active}/{projectsNumber}
                </S.Numbers>{" "}
                {text}
            </S.Text>

            <S.Buttons>
                <S.Button
                    data-testid="prev"
                    onClick={setPrev}
                    active={active !== 1}
                    prev
                >
                    <Arrow />
                </S.Button>
                <S.Button
                    data-testid="next"
                    onClick={setNext}
                    active={active !== projectsNumber}
                >
                    <Arrow />
                </S.Button>
            </S.Buttons>
        </S.Navigation>
    )
}

interface Props {
    active: number
    text: string
    projectsNumber: number
    setActive: React.Dispatch<React.SetStateAction<number>>
}

export default Navigation
