import React, { createElement } from "react"
import { config } from "./config"

const SectionParser = ({ sections }: Props) => {
    const sortedSections = sections.sort((a, b) => a.menuOrder - b.menuOrder)

    const parsedSections = sortedSections.map(section => {
        const {
            acfChildPage: { template, removeMargin, sectionId },
        } = section

        if (!config[template]) return
        return createElement(config[template].section, {
            key: section.id,
            props: {
                ...section[config[template].acf],
                removeMargin,
                sectionId,
            },
        })
    })
    return <>{parsedSections}</>
}

interface Props {
    sections: Array<{
        menuOrder: number
        acfChildPage: {
            template: string
            removeMargin: boolean
            sectionId: string
        }
        id: string
    }>
}

export default SectionParser
