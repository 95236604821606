import {
    Documents,
    Logos,
    MainBanner,
    Projects,
    Support,
    WhoAreWe,
    Team,
} from "../../../sections"
import WithMargin from "../../../hoc/withMargin"

export const config = {
    "documents": {
        section: WithMargin(Documents),
        acf: "acfDocuments",
    },
    "logos": {
        section: WithMargin(Logos),
        acf: "acfLogos",
    },
    "mainBanner": {
        section: WithMargin(MainBanner),
        acf: "acfMainBanner",
    },
    "projects": {
        section: WithMargin(Projects),
        acf: "acfProjects",
    },
    "support": {
        section: WithMargin(Support),
        acf: "acfSupport",
    },
    "team": {
        section: WithMargin(Team),
        acf: "acfTeam",
    },
    "whoAreWe": {
        section: WithMargin(WhoAreWe),
        acf: "acfWhoAreWe",
    },
}
