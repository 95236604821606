import React from "react"
import * as S from "./styled"
import { H3 } from "@headers"
import Logos from "../logos/Logos"
import Visual from "../visual/Visual"
import { Button } from "@common"
import { gatsbyImage } from "@types"
import Gallery from "../gallery/Gallery"
const Project = ({
    active,
    header,
    content,
    logo,
    year,
    days,
    images,
    button,
}: Props) => {
    return (
        <S.Project active={active} className="grid md:grid-cols-12">
            <div className="col-span-12 md:col-span-9">
                <H3 line={100} content={header} />
                <div
                    className="flex flex-col"
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
                <Gallery images={images} />
                <Logos logo={logo} />
            </div>
            <div className="col-span-12 row-start-1 md:col-span-3 md:col-start-10">
                <Visual year={year} days={days} />
            </div>
            <S.BottomBar className="col-span-12">
                <Button title={button.text} url={button.link} />
            </S.BottomBar>
        </S.Project>
    )
}

interface Props {
    active: boolean
    header: string
    content: string
    logo: gatsbyImage
    year: string
    days: string
    images: Array<{
        image: gatsbyImage
    }>
    button: {
        link: string
        text: string
    }
}

export default Project
