import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"
import Download from "@icons/download.svg"

const Support = styled.div`
    background: ${cssVariables.gradient.horizontal};
    color: #fff;

    h3 {
        font-size: ${cssVariables.fonts.large};
        font-family: ${cssVariables.fontFamily.secondary};
        font-weight: 700;
    }
`

const Wrapper = styled.div`
    position: relative;
    max-width: ${cssVariables.hdWidth};
    padding: 30px 0 60px;
    overflow: hidden;
    margin: 0 auto;

    ${breakpoints.md} {
        padding: 30px 0 100px;
    }

    .gatsby-image-wrapper {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        left: 70%;
        height: 100%;

        ${breakpoints.lg} {
            display: block;
        }
    }
`

const CopyButton = styled.button`
    display: flex;
    align-items: center;
    transition: 0.3s;
    margin-top: 30px;
    font-weight: 700;

    &:hover {
        color: ${cssVariables.colors.primary.light};
    }
`

const Icon = styled(Download)`
    margin-left: 10px;
`

const CopyingResponse = styled.span<{ error: boolean }>`
    color: ${({ error }) =>
        error
            ? cssVariables.colors.red.main
            : cssVariables.colors.primary.light};
`

export { Support, CopyButton, Icon, CopyingResponse, Wrapper }
