import React from "react"
import { Heading } from "@common"
import People from "./people/People"
import { GridContainer } from "@styled"
import { PeopleProps } from "./types"

const Team = ({
    props: { content, backgroundText, header, subheader, people },
}: Props) => {
    return (
        <GridContainer>
            <Heading
                subheader={subheader}
                header={header}
                backgroundText={backgroundText}
            />
            <div
                className="text-center"
                dangerouslySetInnerHTML={{ __html: content }}
            />
            <People people={people.slice(0, 4)} />
        </GridContainer>
    )
}

interface Props {
    props: {
        backgroundText: string
        header: string
        subheader: string
        content: string
        people: PeopleProps
    }
}

export default Team
