import React from "react"
import styled from "styled-components"

import { breakpoints } from "@breakpoints"

const MarginWrapper = styled.section<Props>`
    position: relative;
    margin: ${({ removeMargin }) => (removeMargin ? "0px" : "30px 0px 30px 0")};

    ${breakpoints.lg} {
        margin: ${({ removeMargin }) =>
            removeMargin ? "0px" : "50px 0 50px 0"};
    }
`

const WithMargin = (Component: React.FC<any>) => {
    return function WithMarginComponent({ ...props }) {
        const {
            props: { removeMargin, sectionId },
        } = props

        return (
            <MarginWrapper id={sectionId} removeMargin={removeMargin}>
                <Component {...props} />
            </MarginWrapper>
        )
    }
}

export default WithMargin

interface Props {
    removeMargin: boolean
}
