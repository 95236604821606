import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import DownloadIcon from "@icons/download.svg"
import { cssVariables } from "@cssVariables"

const Documents = styled.div`
    padding: 40px 0;
    background: ${cssVariables.colors.dark.secondary};
    overflow: hidden;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    ${breakpoints.md} {
        flex-direction: row;
    }
`

const Files = styled.div`
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    width: 100%;
    margin-top: 15px;

    ${breakpoints.md} {
        margin-top: 0;
        margin-left: 50px;
    }

    ${breakpoints.lg} {
        margin-left: 100px;
        gap: 20px;
        flex-wrap: nowrap;
    }
`
const Heading = styled.div<{ mobile?: true }>`
    display: ${({ mobile }) => (mobile ? "block" : "none")};
    flex-shrink: 0;

    ${breakpoints.md} {
        display: ${({ mobile }) => (mobile ? "none" : "block")};
    }
`

const File = styled.div<{ last: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${({ last }) => (last ? "100%" : "50%")};
    margin-bottom: 50px;
`

const Download = styled.div`
    display: flex;
    align-items: center;
    color: ${cssVariables.colors.dark.light};
    transition: 0.3s;
    z-index: 10;

    &:hover {
        color: ${cssVariables.colors.primary.light};
    }
`

const Icon = styled(DownloadIcon)`
    margin-right: 10px;
`

export { Documents, Wrapper, Heading, Files, File, Download, Icon }
