import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"
import styled from "styled-components"

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 30px;

    ${breakpoints.md} {
        align-items: center;
        margin-top: -50px;
        margin-bottom: 0;
    }
`
const Background = styled.div`
    display: none;
    position: absolute;
    top: 50px;
    left: 10%;
    width: 80%;
    height: 60%;
    background-color: ${cssVariables.colors.gray.medium};
    border-radius: 30px;
    z-index: -1;

    ${breakpoints.md} {
        border-radius: 60px;
        top: 100px;
    }
`

export { Container, Background }
