import React from "react"
import * as S from "./styled"
import { Mouse } from "@styled"

const ScrollButton = ({ url }: Props) => {
    return (
        <S.ScrollButton href={url}>
            <Mouse />
        </S.ScrollButton>
    )
}

interface Props {
    url: string
}

export default ScrollButton
