import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const Logos = styled.div`
    display: flex;
    align-items: center;
    font-size: ${cssVariables.fonts.xxLarge};
    margin: 50px 0;

    svg,
    .gatsby-image-wrapper {
        width: 50%;
        max-height: 75px;

        ${breakpoints.md} {
            width: 170px;
        }
    }
`

const Plus = styled.span`
    margin: 0 30px;
`

export { Logos, Plus }
