import React from "react"
import * as S from "./styled"

const Date = ({ year, days }: Props) => {
    return (
        <S.Date>
            <S.Year dangerouslySetInnerHTML={{ __html: year }} />
            <S.Days dangerouslySetInnerHTML={{ __html: days }} />
        </S.Date>
    )
}

interface Props {
    year: string
    days: string
}

export default Date
