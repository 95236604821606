import React from "react"
import * as S from "./styled"
import { GatsbyImage } from "gatsby-plugin-image"
import { gatsbyImage } from "@types"

const Images = ({ images }: Props) => {
    return (
        <>
            {images?.map(({ image }, index) => (
                <S.Image key={index}>
                    <GatsbyImage
                        alt=""
                        image={image.localFile.childImageSharp.gatsbyImageData}
                    />
                </S.Image>
            ))}
        </>
    )
}

interface Props {
    images: Array<{
        image: gatsbyImage
    }>
}

export default Images
