import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"
import styled from "styled-components"

const People = styled.div`
    font-size: ${cssVariables.fonts.base};
    margin-top: 20px;

    ${breakpoints.md} {
        margin-top: 60px;
    }

    .gatsby-image-wrapper {
        border-radius: ${cssVariables.borderRadius};
        margin-bottom: 40px;

        ${breakpoints.md} {
            height: 420px;
        }
    }
`

const Position = styled.span`
    display: block;
    font-weight: 700;
    margin: 20px 0;
`

const Person = styled.div`
    margin-bottom: 50px;
`

export { People, Position, Person }
