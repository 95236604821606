import styled from "styled-components"
import { breakpoints } from "@breakpoints"

const BottomBar = styled.div`
    margin: 10px auto;

    ${breakpoints.md} {
        margin: inherit;
        margin-top: 20px;
    }
`

const Project = styled.div<{ active: boolean }>`
    display: ${({ active }) => (active ? "grid" : "none")};
`

const ImageContainer = styled.div`
    display: grid;
    grid-template-columns: auto;
    column-gap: 10px;
    row-gap: 10px;
    padding-top: 20px;

    ${breakpoints.sm} {
        grid-template-columns: auto auto;
    }

    ${breakpoints.md} {
        grid-template-columns: auto auto auto;
    }
`

const ImageWrapper = styled.div`
    width: 100%;
`

export { BottomBar, Project, ImageContainer, ImageWrapper }
