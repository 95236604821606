import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const Wrapper = styled.div`
    overflow-x: clip;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0 0;

    ${breakpoints.md} {
        padding: 40px 0 150px 0;
        margin-bottom: 0;
    }
`

const Content = styled.div`
    ${breakpoints.md} {
        z-index: 2;
    }

    & button {
        margin-bottom: 160px;
    }
`

const ButtonWrappers = styled.div`
    margin-bottom: 20px;

    ${breakpoints.md} {
        margin-bottom: 160px;
    }
`

const Text = styled.div`
    padding: 20px 0 10px 0;

    ${breakpoints.md} {
        font-size: ${cssVariables.fonts.large};
    }
`

const Image = styled.div`
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 40px;

    ${breakpoints.md} {
        display: block;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: calc(50% - 55px);
        width: 100%;
        max-width: 1120px;
        border-radius: 0 0 0 60px;
    }
`

export { Wrapper, Content, ButtonWrappers, Text, Image }
