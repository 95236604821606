import React, { useState, useEffect } from "react"
import * as S from "./styled"
import { GatsbyImage } from "gatsby-plugin-image"
import { gatsbyImage } from "@types"
import { Arrow } from "@styled"

const Gallery = ({ images }: Props) => {
    const [lightboxVisible, setLightboxVisible] = useState(false)
    const [selectedImage, setSelectedImage] = useState(0)
    const [prevDisabled, setPrevDisabled] = useState(true)
    const [nextDisabled, setNextDisabled] = useState(true)

    const imageNumber = images.length - 1

    const openModal = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        index: number
    ) => {
        e.preventDefault()
        setLightboxVisible(true)
        setSelectedImage(index)
        document.body.style.overflow = "hidden"
    }

    const closeModal = () => {
        setLightboxVisible(false)
        setSelectedImage(0)
        document.body.style.overflow = "unset"
    }

    const goBack = () => {
        setSelectedImage(selectedImage => selectedImage - 1)
    }

    const goForward = () => {
        setSelectedImage(selectedImage => selectedImage + 1)
    }

    useEffect(() => {
        if (selectedImage === 0) {
            setPrevDisabled(true)
        } else {
            setPrevDisabled(false)
        }

        if (selectedImage === imageNumber) {
            setNextDisabled(true)
        } else {
            setNextDisabled(false)
        }
    }, [selectedImage])

    return (
        <>
            <S.Gallery>
                {images?.map(({ image }, index) => (
                    <S.Image
                        key={
                            image.localFile.childImageSharp.gatsbyImageData
                                .images.fallback.src
                        }
                    >
                        <a
                            href={
                                image.localFile.childImageSharp.gatsbyImageData
                                    .images.fallback.src
                            }
                            onClick={e => openModal(e, index)}
                        >
                            <GatsbyImage
                                alt=""
                                image={
                                    image.localFile.childImageSharp
                                        .gatsbyImageData
                                }
                            />
                        </a>
                    </S.Image>
                ))}
            </S.Gallery>

            <S.LightboxModal visible={lightboxVisible}>
                <S.Backdrop onClick={closeModal} />
                <S.LightboxContent>
                    <GatsbyImage
                        alt=""
                        image={
                            images[selectedImage].image.localFile
                                .childImageSharp.gatsbyImageData
                        }
                        onClick={closeModal}
                    />
                    <S.Controls>
                        <S.Button
                            onClick={goBack}
                            disabled={prevDisabled}
                            active={!prevDisabled}
                            prev
                        >
                            <Arrow />
                        </S.Button>
                        <S.Button
                            onClick={goForward}
                            disabled={nextDisabled}
                            active={!nextDisabled}
                        >
                            <Arrow />
                        </S.Button>
                    </S.Controls>
                </S.LightboxContent>
            </S.LightboxModal>
        </>
    )
}

interface Props {
    images: Array<{
        image: gatsbyImage
    }>
}

export default Gallery
