import React from "react"
import * as S from "./styled"
import { Heading } from "@common"

const Header = ({ backgroundText, subheader, header }: Props) => {
    const renderHeading = (position: "left" | "center", mobile?: true) => {
        return (
            <S.Heading mobile={mobile}>
                <Heading
                    position={position}
                    light
                    offset
                    backgroundText={backgroundText}
                    subheader={subheader}
                    header={header}
                />
            </S.Heading>
        )
    }

    return (
        <>
            {renderHeading("left")}
            {renderHeading("center", true)}
        </>
    )
}

interface Props {
    backgroundText: string
    subheader: string
    header: string
}

export default Header
