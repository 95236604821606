import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"
import styled from "styled-components"

const Navigation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    ${breakpoints.md} {
        margin-top: -100px;
        align-items: flex-end;
    }
`

const Text = styled.div`
    display: flex;
    font-size: ${cssVariables.fonts.large};
    font-family: ${cssVariables.fontFamily.secondary};
`

const Numbers = styled.span`
    font-weight: 700;
`

const Button = styled.div<{ prev?: true; active: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-right: ${({ prev }) => (prev ? "10px" : 0)};
    background: ${({ active }) =>
        active
            ? cssVariables.gradient.vertical
            : cssVariables.colors.gray.medium};
    transform: ${({ prev }) => (prev ? "rotate(180deg)" : "none")};
    border-radius: 50%;
    transition: 0.3s;
    cursor: pointer;
`

const Buttons = styled.div`
    display: flex;
    margin-top: 10px;
`

export { Text, Numbers, Navigation, Button, Buttons }
