import React from "react"
import * as S from "./styled"

const Box = ({ header, content }: Props) => {
    return (
        <S.Box>
            <S.Header dangerouslySetInnerHTML={{ __html: header }} />
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </S.Box>
    )
}

interface Props {
    header: string
    content: string
}

export default Box
