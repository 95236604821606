import React from "react"
import * as S from "./styled"
import Logo from "@icons/logo.svg"
import { GatsbyImage } from "gatsby-plugin-image"
import { gatsbyImage } from "@types"

const Logos = ({ logo }: Props) => {
    return (
        <S.Logos>
            <Logo data-testid="mainLogo" />
            <S.Plus>+</S.Plus>
            <GatsbyImage
                alt=""
                image={logo.localFile.childImageSharp.gatsbyImageData}
            />
        </S.Logos>
    )
}

interface Props {
    logo: gatsbyImage
}

export default Logos
