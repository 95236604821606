import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"
import styled from "styled-components"

const Link = styled.a`
    border: 1px solid ${cssVariables.colors.gray.main};
    border-radius: 8px;
    overflow: hidden;

    ${breakpoints.md} {
        border-radius: 15px;
    }
`

export { Link }
