import React from "react"
import { GridContainer } from "@styled"
import Box from "./box/Box"
import * as S from "./styled"
import { gatsbyImage } from "@types"
import { Heading } from "@common"
import Images from "./images/Images"

const WhoAreWe = ({
    props: { backgroundText, box, content, header, subheader, images },
}: Props) => {
    return (
        <S.Wrapper>
            <GridContainer>
                <div className="grid md:grid-cols-12">
                    <S.Content className="md:col-span-6">
                        <Heading
                            position="left"
                            header={header}
                            subheader={subheader}
                            backgroundText={backgroundText}
                        />
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </S.Content>
                    <S.Images className="md:col-span-6">
                        <Images images={images} />
                    </S.Images>
                </div>
                <Box header={box.header} content={box.content} />
            </GridContainer>
        </S.Wrapper>
    )
}

interface Props {
    props: {
        backgroundText: string
        box: { content: string; header: string }
        content: string
        header: string
        subheader: string
        images: Array<{
            image: gatsbyImage
        }>
    }
}

export default WhoAreWe
