import styled from "styled-components"
import { breakpoints } from "@breakpoints"

const Wrapper = styled.div`
    position: relative;
`

const Content = styled.div`
    margin-top: 40px;
    ${breakpoints.md} {
        margin-top: 0;
        margin-right: 50px;
        padding-bottom: 150px;
    }
`

const Images = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 60px 0 30px;
    z-index: -1;

    ${breakpoints.md} {
        margin: 70px 0 0;
    }
`

export { Wrapper, Content, Images }
