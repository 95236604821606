import React, { useState } from "react"
import * as S from "./styled"
import { Heading } from "@common"
import { GridContainer } from "@styled"
import { gatsbyImage } from "@types"
import { GatsbyImage } from "gatsby-plugin-image"
import { cssVariables } from "@cssVariables"

const Support = ({
    props: {
        backgroundText,
        bankAccountNumber,
        copyBankAccountNumberText,
        header,
        leftContent,
        rightContent,
        subheader,
        successfullyCopiedText,
        errorCopyingText,
        image,
    },
}: Props) => {
    const [copyResponse, setCopyResponse] = useState({
        text: "",
        error: false,
    })
    const copyAccountNumber = () => {
        navigator.clipboard.writeText(bankAccountNumber).then(
            () => {
                setCopyResponse({ text: successfullyCopiedText, error: false })
            },
            () => {
                setCopyResponse({
                    text: errorCopyingText,
                    error: true,
                })
            }
        )
    }

    return (
        <S.Support>
            <S.Wrapper>
                <GridContainer>
                    <Heading
                        subheader={subheader}
                        header={header}
                        backgroundText={backgroundText}
                        position="left"
                        light
                        offset
                        backgroundColor={cssVariables.colors.primary.main}
                    />
                </GridContainer>

                <GridContainer className="grid md:grid-cols-12">
                    <div className="md:col-span-6 lg:col-span-4">
                        <div
                            dangerouslySetInnerHTML={{ __html: leftContent }}
                        />
                    </div>
                    <div className="md:col-span-5 md:col-start-8 lg:col-span-3 lg:col-start-6">
                        <div
                            dangerouslySetInnerHTML={{ __html: rightContent }}
                        />
                        <S.CopyButton
                            role="button"
                            onClick={() => copyAccountNumber()}
                        >
                            {copyBankAccountNumberText}
                            <S.Icon />
                        </S.CopyButton>
                        {copyResponse.text !== "" && (
                            <S.CopyingResponse
                                error={copyResponse.error}
                                dangerouslySetInnerHTML={{
                                    __html: copyResponse.text,
                                }}
                            />
                        )}
                    </div>
                </GridContainer>
                <GatsbyImage
                    alt=""
                    image={image.localFile.childImageSharp.gatsbyImageData}
                />
            </S.Wrapper>
        </S.Support>
    )
}

interface Props {
    props: {
        backgroundText: string
        bankAccountNumber: string
        copyBankAccountNumberText: string
        header: string
        leftContent: string
        rightContent: string
        subheader: string
        successfullyCopiedText: string
        errorCopyingText: string
        image: gatsbyImage
    }
}

export default Support
