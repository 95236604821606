import { cssVariables } from "@cssVariables"
import styled from "styled-components"

const Image = styled.div`
    box-shadow: ${cssVariables.boxShadow};
    width: 48%;
    border-radius: ${cssVariables.borderRadius};
    overflow: hidden;
`
export { Image }
