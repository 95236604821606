import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"
import styled from "styled-components"

const Date = styled.div`
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 5px;
    background: ${cssVariables.gradient.vertical};
    border-radius: 15px;
    box-shadow: ${cssVariables.boxShadow};
    color: #fff;
    width: 75px;
    height: 75px;
    text-align: center;
    font-family: ${cssVariables.fontFamily.secondary};
    font-weight: 700;
    z-index: 10;

    ${breakpoints.sm} {
        width: 175px;
        height: 175px;
    }
`

const Year = styled.span`
    font-size: ${cssVariables.fonts.large};

    ${breakpoints.sm} {
        font-size: 50px;
    }
`

const Days = styled.span`
    font-size: ${cssVariables.fonts.xSmall};
    line-height: 1;

    ${breakpoints.sm} {
        font-size: ${cssVariables.fonts.base};
        line-height: 1;
        margin-top: 20px;
    }
`

export { Date, Year, Days }
