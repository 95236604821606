import React from "react"
import { GridContainer } from "@styled"
import { cssVariables } from "@cssVariables"
import { gatsbyImage } from "@types"
import { GatsbyImage } from "gatsby-plugin-image"
import { H1, H4 } from "@headers"
import { Button } from "@common"
import ScrollButton from "./scrollButton/ScrollButton"
import * as S from "./styled"

const MainBanner = ({
    props: { header, subheader, text, button, scrollId, image },
}: Props) => {
    return (
        <S.Wrapper>
            <GridContainer>
                <div className="grid md:grid-cols-12">
                    <S.Content className="md:col-span-5">
                        <H4
                            content={subheader}
                            color={cssVariables.colors.dark.light}
                        ></H4>
                        <H1 content={header}></H1>
                        <S.Text
                            className="text"
                            dangerouslySetInnerHTML={{ __html: text }}
                        ></S.Text>
                        <S.ButtonWrappers>
                            <Button
                                title={button.title}
                                url={button.url}
                                target={button.target}
                            />
                        </S.ButtonWrappers>
                    </S.Content>

                    <S.Image className="md:col-span-7">
                        <GatsbyImage
                            alt=""
                            image={
                                image.localFile.childImageSharp.gatsbyImageData
                            }
                        />
                    </S.Image>
                    <ScrollButton url={scrollId} />
                </div>
            </GridContainer>
        </S.Wrapper>
    )
}
interface Props {
    props: {
        header: string
        subheader: string
        text: string
        image: gatsbyImage
        scrollId: string
        button: {
            title: string
            url: string
            target: string
        }
    }
}

export default MainBanner
